import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Quotes`}</h1>
    <p>{`Here are a collection of my favorite quotes.`}</p>
    <h2>{`Dreams`}</h2>
    <p>{`"I learned this, at least, by my experiment: that if one advances confidently in the direction of his dreams, and endeavors to live the life which he has imagined, he will meet with a success unexpected in common hours."`}</p>
    <p>{`—Henry David Thoreau, `}<em parentName="p">{`Walden`}</em></p>
    <p>{`Priest: "My nephew left home, saying he'd make his name with his sword. Five years ago, he died on the battlefield, just some nameless foot soldier. If he had stayed alive, he would have had a family, and some happiness with it. How foolish." `}<br />{`
Guts: "What's so bad about that? No matter the cause, he died doing what he wanted, right? I'd call him a lucky man."`}</p>
    <p>{`—`}<em parentName="p">{`Berserk Vol. 1: The Brand`}</em></p>
    <h2>{`Destiny`}</h2>
    <p>{`"In this world, `}<br />{`
is the destiny of mankind controlled `}<br />{`
by some transcendental entity or law?`}</p>
    <p>{`Is it like the hand of God `}<br />{`
hovering above?`}</p>
    <p>{`At least it is true, `}<br />{`
that man has no control, `}<br />{`
even over his own will."`}</p>
    <p>{`—Void, `}<em parentName="p">{`Berserk`}</em></p>
    <h2>{`Freedom`}</h2>
    <p>{`"The truth shall set you free."`}</p>
    <p>{`—Jesus, `}<em parentName="p">{`John 8:32`}</em></p>
    <p>{`"It is easy in the world to live after the world’s opinion; it is easy in solitude to live after our own; but the great man is he who in the midst of the crowd keeps with perfect sweetness the independence of solitude."`}</p>
    <p>{`—Ralph Waldo Emerson, `}<em parentName="p">{`Self-Reliance`}</em></p>
    <p>{`「他人の戦いにまきこまれて死ねようなやつはザコだ。自分の命さえ自由にできないなら死んじまえってな。あの二人にはオレという災いから身を守る力が無かった。それだけのことさ。アリを踏み潰すことは気にしてたら歩くこともできゃしねえんだ。」 `}<br /></p>
    <p>{`—Guts, `}<em parentName="p">{`Berserk (2016) Ep. 1: The Dragonslayer`}</em></p>
    <p>{`"At times the whole world seems to be in conspiracy to importune you with emphatic trifles. Friend, client, child, sickness, fear, want, charity, all knock at once at thy closet door and say,— ‘Come out unto us.’ But keep thy state; come not into their confusion. The power men possess to annoy me I give them by a weak curiosity. No man can come near me but through my act."`}</p>
    <p>{`—Ralph Waldo Emerson, `}<em parentName="p">{`Self-Reliance`}</em></p>
    <p>{`"Freedom, baby... is never havin' to say you're sorry."`}</p>
    <p>{`—John Milton, `}<em parentName="p">{`The Devil's Advocate`}</em></p>
    <p>{`"Take a good look, kid. This is what's above. It's the surface." `}<br />{`
"There's nothing here." `}<br />{`
"Yup, not a thing." `}<br />{`
"Huh?" `}<br />{`
"That's why it's good. With no walls and no ceilings, this is a world for men. Coming? ...I see. When you grow up, come."`}</p>
    <p>{`—Kamina's father inviting him to a new world on the surface, `}<em parentName="p">{`Gurenn Lagann Ep. 2`}</em></p>
    <h2>{`Power`}</h2>
    <p>{`"First principle: one must need to be strong—otherwise one will never become strong."`}</p>
    <p>{`—Friedrich Nietzsche, `}<em parentName="p">{`Twilight of the Idols: Skirmishes of an Untimely Man 38`}</em></p>
    <p>{`"The man must be so much that he must make all circumstances indifferent."`}</p>
    <p>{`— Ralph Waldo Emerson, `}<em parentName="p">{`Self-Reliance`}</em></p>
    <p>{`Gon: "I don't care...if this is the end. So...I'll use everything. I'll kill you." `}<br />{`
Pitou: `}<em parentName="p">{`Thank goodness... This power could only be achieved through the sacrifice of his own life energy. He's prepared to never use Nen again. That was the resolve required to perform this feat. An enemy blessed with immense talent has been forced to exchange all of that talent for this power. I'm so glad...I am the one...that he will kill.`}</em></p>
    <p>{`— `}<em parentName="p">{`Hunter x Hunter ep. 131`}</em></p>
    <p>{`"His philosophy is to be the strongest of them all."`}</p>
    <p>{`— Nobunaga on Uvogin, `}<em parentName="p">{`Hunter x Hunter ep. 43`}</em></p>
    <p>{`"And one more thing...I don't like elves, and I don't like `}<em parentName="p">{`you`}</em>{`. Because you're `}<em parentName="p">{`weak`}</em>{`. It pisses me off when I see `}<em parentName="p">{`weaklings`}</em>{`. I feel like I wanna `}<em parentName="p">{`crush`}</em>{` them. They can't do a thing on their own. All they ever do is talk and buzz around people like flies. Makes me wanna `}<em parentName="p">{`squash 'em`}</em>{`."`}</p>
    <p>{`— Guts to Puck, `}<em parentName="p">{`Berserk Vol. 1: The Brand`}</em></p>
    <p>{`"Take your philosophy, be crushed, and be gone."`}</p>
    <p>{`— Uchiha Madara to The Five Kage, `}<em parentName="p">{`Naruto Shippuden Ep. 339`}</em></p>
    <p>{`"When you connect to the stress mechanisms inside yourself, you become stronger; when you know you're facing danger. When you go to face a force, you set your mind to it, and that is what we lost in our society. It's always comfort, comfort, comfort, comfort—no, seek discomfort! That discomfort becomes power. That's the point where you are learning. And there you get new power. You go past the conditioning."`}</p>
    <p>{`—Wim Hof, `}<em parentName="p"><a parentName="em" {...{
          "href": "https://youtu.be/W8ALm2M09qA?t=2486"
        }}>{`The Mikhaila Peterson Podcast #36`}</a></em></p>
    <h2>{`Spirit`}</h2>
    <p>{`"Incredible mental fortitude... He's well aware that he's about to be tortured, but the aura around his body remains firm. That iron body is built on a rock-solid spirit. That is why his aura is so strong, the product of countless battles..."`}</p>
    <p>{`— Kurapika analyzing a captured Uvogin, `}<em parentName="p">{`Hunter x Hunter ep. 45`}</em></p>
    <h2>{`Virtue`}</h2>
    <p>{`"Your goodness must have some edge to it — else it is none."`}</p>
    <p>{`—Ralph Waldo Emerson, `}<em parentName="p">{`Self-Reliance`}</em></p>
    <h2>{`Vice`}</h2>
    <p>{`"The worst vice is advice."`}</p>
    <p>{`— John Milton, `}<em parentName="p">{`The Devil's Advocate`}</em></p>
    <h3>{`Pride`}</h3>
    <p>{`"Vanity...is definitely my favorite sin. Kevin, it's so basic. Self-love...the all-natural opiate."`}</p>
    <p>{`— John Milton, `}<em parentName="p">{`The Devil's Advocate`}</em></p>
    <h3>{`Lust`}</h3>
    <p>{`"Diaboli virtus in lumbis est."`}</p>
    <p>{`— John Milton, `}<em parentName="p">{`The Devil's Advocate`}</em></p>
    <h3>{`Envy`}</h3>
    <p>{`"The envious man was now happy for the first time in his life. He had it in his power to ruin a person of virtue and merit."`}</p>
    <p>{`— Voltaire, `}<em parentName="p">{`Zadig`}</em></p>
    <h2>{`Morality`}</h2>
    <p>{`"Let him without sin cast the first stone."`}</p>
    <p>{`— Jesus, `}<em parentName="p">{`John 8:7 (my paraphrase)`}</em></p>
    <p>{`"Now I understand why this kid interests me. He doesn't care about right or wrong. When I admitted to making forgeries, and when that trader gave me a hard time, he didn't react with accusatory glares, or by plotting his own evil deeds. His reaction was very simple: innocent curiosity! He opens his mind to everything that impresses him, right or wrong. Which means that he's dangerous...someone who can never be fully appraised."`}</p>
    <p>{`— Zepile appraising Gon, `}<em parentName="p">{`Hunter x Hunter ep. 48`}</em></p>
    <h2>{`God`}</h2>
    <p>{`"Let me give you a little inside information about God. God likes to watch. He's a prankster. Think about it. He gives man...instincts. He gives you this extraordinary gift, and then what does he do? I swear, for his own amusement, his own private cosmic gag reel, he sets the rules in opposition. It's the goof of all time. Look, but don't touch. Touch, but don't taste. Taste—don't swallow. Ha ha ha. And while you're jumpin' from one foot to the next, what is he doin'? He's laughin' his sick fuckin' ass off. He's a tight-ass. He's a sadist. He's an absentee landlord. Worship that? Never!"`}</p>
    <p>{`— John Milton, `}<em parentName="p">{`The Devil's Advocate`}</em></p>
    <h2>{`Satan`}</h2>
    <p>{`"I'm here on the ground with my nose in it since the whole thing began. I've nurtured every sensation man has been inspired to have. I cared about what he wanted, and I never judged him. Why? Because I never rejected him. In spite of all his imperfections, I'm a fan of man! I'm a humanist. Maybe the last humanist."`}</p>
    <p>{`— John Milton, `}<em parentName="p">{`The Devil's Advocate`}</em></p>
    <p>{`Kevin Lomax: "In the Bible, you lose." `}<br />{`
John Milton: "Well, consider the source, son."`}</p>
    <p>{`— `}<em parentName="p">{`The Devil's Advocate`}</em></p>
    <h2>{`Society`}</h2>
    <p>{`"Eddie Barzoon, Eddie Barzoon. Ha! I nursed him through two divorces, a cocaine rehab, and a pregnant receptionist. God's creature, right? God's special creature? Ha! And I've warned him, Kevin. I've warned him every step of the way. Watching him bounce around like a fucking game, like a wind-up toy, like 250 pounds of self-serving greed on wheels. The next thousand years is right around the corner, Kevin. Eddie Barzoon—take a good look, because he's the poster child for the next millenium. These people—it's no mystery where they come from. You sharpen the human appetite to the point where it can split atoms with its desire. You build egos the size of cathedrals, fiber optically connect the world to every eager impulse. Grease even the dullest dreams with these...dollar-green, gold-plated fantasies until every human becomes an aspiring emperor, `}<em parentName="p">{`becomes his own God`}</em>{`. And where can you go from there? And as we're scrambling from one deal to the next, who's got his eye on the planet, as the air thickens, the water sours? Even the bee's honey takes on the metallic taste of radioactivity, and it just keeps coming. Faster and faster. There's no chance to think, to prepare. It's "buy futures, sell futures", when there `}<em parentName="p">{`is`}</em>{` no future. We got a runaway train, boy. We got a billion Eddie Barzoons all jogging into the future. Every one of 'em getting ready to fist fuck God's ex-planet, lick their fingers clean as they reach out toward their pristine cybernetic keyboards to tote up their fucking billable hours. And then it hits home. You gotta pay your own way, Eddie. It's a little late in the game to buy out now. Your belly's too full, your dick is sore, your eyes are bloodshot, and you're screaming for someone to help—but guess what? There's no one there! You're all alone, Eddie. You're God's special little creature."`}</p>
    <p>{`— John Milton, `}<em parentName="p">{`The Devil's Advocate`}</em></p>
    <h2>{`Training`}</h2>
    <p>{`"You can train hard, or you can train long."`}</p>
    <p>{`— Christian Thibaudeau`}</p>
    <p>{`"You can only train as hard as you can recover."`}</p>
    <p>{`— Unknown`}</p>
    <p>{`"Don't major in the minors."`}</p>
    <p>{`— Stan Efferding`}</p>
    <h2>{`Zen`}</h2>
    <p>{`"Even so, with each step his staff banged the floor with the steel of the Zen will that informed his gentle exterior."`}</p>
    <p>{`—Huston Smith, `}<em parentName="p">{`Preface to Zen Mind, Beginner's Mind`}</em></p>
    <p>{`"Walking with you in Buddha's gentle rain `}<br />{`
Our robes are soaked through `}<br />{`
But on the lotus leaves `}<br />{`
Not a drop remains"`}</p>
    <p>{`—Richard Baker, `}<em parentName="p">{`Preface to Zen Mind, Beginner's Mind`}</em></p>
    <p>{`"As confusion disappears, so does suffering."`}</p>
    <p>{`—Ringu Tulku`}</p>
    <h2>{`Misc`}</h2>
    <p>{`"Man has it all in his hands, and it all slips through his fingers from sheer cowardice."`}</p>
    <p>{`— Fyodor Dostoyevsky, `}<em parentName="p">{`Crime and Punhisment`}</em></p>
    <p>{`"In the abyss of despair...only he who stands up with broken sword in hand...perhaps..."`}</p>
    <p>{`—Skull Knight, `}<em parentName="p">{`Berserk Ch. 37`}</em></p>
    <p>{`"Chop your own wood and it will warm you twice."`}</p>
    <p>{`—Henry Ford`}</p>
    <p>{`"When in doubt, use brute force."`}</p>
    <p>{`—Ken Thompson`}</p>
    <p>{`"The first principle is that you must not fool yourself, and you're the easiest person to fool."`}</p>
    <p>{`— Richard Feynman, `}<em parentName="p">{`Cargo Cult Science`}</em></p>
    <p>{`「オレには知らぬことは二つだ。後悔と不可能だ。」`}</p>
    <p>{`— Anos Voldigord, `}<em parentName="p">{`The Misfit of Demon King Academy Ep. 4`}</em></p>
    <p>{`Anos:「魔王とはなんだ？力か？称号か？権力か？」`}<br />{`
Ivis:「その全てだ！」 `}<br />{`
Anos:「いいえ。どれでもない。オレがオレであるということだ。」`}<br /></p>
    <p>{`— `}<em parentName="p">{`The Misfit of Demon King Academy Ep. 4`}</em></p>
    <p>{`Ash: "Well, if you just try hard enough, things will work out, won't they?" `}<br />{`
Misty: "No, Ash, I'm afraid things won't just work out if you try hard enough. The Pokemon trainer's judgement is more important than anything else..."`}</p>
    <p>{`—`}<em parentName="p">{`Pokemon: Indigo League S1:E3`}</em></p>
    <p>{`Misty: "Well, if they're going to use two Pokemon, I don't see why we can't use two Pokemon." `}<br />{`
Ash: "The Pokemon League rules say only one at a time." `}<br />{`
Misty: "Ash, if you follow the rules, you're going to lose all your Pokemon."`}</p>
    <p>{`—`}<em parentName="p">{`Pokemon: Indigo League S1:E3`}</em></p>
    <p>{`"Don't get too cocky, my boy, no matter how good you are. Don't ever let 'em see you coming. That's the gaffe, my friend. You gotta keep yourself small, innocuous, and be the little guy. You know, the nerd, the leper, shit-kicking surfer. Look at me. Underestimated from day one. You'd never think I was a master of the universe, now would ya? ... You're missing what I have. There's this beautiful girl just fucked me 40 ways from Sunday. We're done. She's walking to the bathroom. She's `}<em parentName="p">{`trying`}</em>{` to walk. She turns. Ha ha ha. She looks. It's me. Not the Trojan army just fucked her. Little old me. She has this look on her face like, "How the hell did that happen?" I'm the hand up Mona Lisa's skirt. I'm a surprise, Kevin. They don't see me comin'. That's what you're missing."`}</p>
    <p>{`— John Milton, `}<em parentName="p">{`The Devil's Advocate`}</em></p>
    <p>{`"Guilt...it's like a bag of fuckin' bricks. All you gotta do...is set it down."`}</p>
    <p>{`— John Milton, `}<em parentName="p">{`The Devil's Advocate`}</em></p>
    <p>{`"Free will...it `}<em parentName="p">{`is`}</em>{` a bitch."`}</p>
    <p>{`— John Milton, `}<em parentName="p">{`The Devil's Advocate`}</em></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      